/* eslint-disable max-lines */
/*
        Name : Config File v1.0
        Purpose: For storing all the common configurations of the application
        Created Date: 5th Oct 2018
*/
import FeatureFlag from '@core/utils/featureFlag';

const config = {
  lang: 'en',
  dateFormat: 'DD-MM-YYYY',
  yearMonthDateWithZeroTime: 'YYYY-MM-DD hh:mm:ss',
  yearMonthDateWithTZeroTime: 'YYYY-MM-DDT00:00',
  yearMonthDateFormat: 'YYYY-MM-DD',
  yearMonthDateWithTime: 'YYYY-MM-DD h:mm A',
  mapDefaultZoom: 7,
  mapDefaultZoomPDB: 8,
  mapDefaultZoomPETCO: 7,
  defaultLat: 3.139,
  defaultLong: 101.6869,
  defaultLatPDB: 2.1896, // -1.0,
  defaultLongPDB: 102.2501, // 108.0478,
  defaultLatPETCO: 4.4725, // 6.3,
  defaultLongPETCO: 97.9756, // 112.12166666666666,
  dateLabelFormat: 'Do MMM',
  dateSubFormat: 'DD MMM YYYY',
  monthYearFormat: 'MMM YYYY',
  smallMonthYear: 'MM YYYY',
  fullMonthYearFormat: 'MMMM YYYY',
  numMonthYearFormat: 'MM-YYYY',
  dateMonth: 'DD MMM',
  Monthdate: 'MMM DD',
  monthFormat: 'MMMM',
  dateTimeFormat: 'h:mm A, DD MMM',
  dateOnlyFormat: 'DD',
  monthShortFormat: 'MMM',
  month: 'M',
  monthYearSlashSeperator: 'MM/YYYY',
  dateMonthYearWithTime12: 'DD MMM, YYYY, h:mm A',
  dateMonthYearTime12: 'DD MMM YYYY, h:mm A',
  dateMonthYearTime: 'DD MMM YYYY, h:mm',
  dateMonthYearTimeZero: 'DD MMM YYYY, h:00 A',
  dateMonthYearTime12Dot: 'DD MMM YYYY, h.mm A',
  dateMonthYearWithTime24: 'DD MMM, YYYY, hh:mm A',
  timehh: 'hh:mm A',
  timeHH: 'HH:mm',
  DDMMYYYYSlashSeperator: 'DD/MM/YYYY',
  fullDateCompressed: 'YYYYMMDD',
  smallDateFormat: 'dd-mm-yyyy',
  dateWithDayFormat: 'DD MMM YYYY, dddd',
  dateMonthTimeFormat: 'DD MMM, h:mm A',
  dateMonthTimeFormatDot: 'DD MMM, h.mm A',
  dateMonthTimeTwentyfourHrFormat: 'DD MMM, HH:mm',
  dateMonthTimeTwentyfourFormat: 'DD MMM YYYY, HH:mm',
  dateShortFormat: 'DD-MMM-YY',
  fullyearFormat: 'DD-MMM-YYYY',
  yearDateFormat: 'YYYY-MM-DD',
  fullDateWithTime: 'DD MMM YYYY, hh:mm A',
  lastUpdatedTimeFormat: 'Do MMM YYYY [at] h:mm A',
  lastUpdateTimeFormat: 'DD MMM YYYY [at] h:mm A',
  approvedPriceTimeFormat: 'DD MMM YYYY, h:mm A',
  timeDateYearFormat: 'h:mm A, DD MMM YYYY',
  timeTwentyfourDateYearFormat: 'HH:mm, DD MMM YYYY',
  timeDateYearFormatDot: 'h.mm A, DD MMM YYYY',
  dateDoMonthYearFormat: 'DD MMM YYYY',
  yearMonth: 'YYYY-MM',
  year: 'YYYY',
  monthShortYearFormat: 'MMM YY',
  mapKey: 'AIzaSyDasPCze-GTH4igbqRMIW-3BC7tmwgeK9U',
  LOGGER: true,
  importparityCostElemId: 6026,
  env: {
    development: {},
    production: {},
    test: {},
    qa: {},
    staging: {},
    prime_devlocal: {
      baseUrl: 'https://api.primedev.dove.petronas.com',
      auth: '/auth/login?platform=weblocal',
    },
    primedev: {},
    primeqa: {},
    mock: {},
  },
  simulatorEndpoints: {
    qa: {
      url: 'https://cloud.anylogic.petronas.com',
      clientID: 'f1132f5a-b0cb-4c73-bc65-5079f493492c',
      modelName: 'DEV - DOVE Primary logistics and inventory level simulation',
      env: 'PRIMEDEV',
    },
    primedev: {
      url: 'https://cloud.anylogic.petronas.com',
      clientID: 'f1132f5a-b0cb-4c73-bc65-5079f493492c',
      modelName: 'DEV - DOVE Primary logistics and inventory level simulation',
      env: 'PRIMEDEV',
    },
    primeqaint: {
      url: 'https://cloud.anylogic.petronas.com',
      clientID: 'f1132f5a-b0cb-4c73-bc65-5079f493492c',
      modelName: 'DEV - DOVE Primary logistics and inventory level simulation',
      env: 'PRIMEINT',
    },
    uat: {
      url: 'https://cloud.anylogic.petronas.com',
      clientID: 'f1132f5a-b0cb-4c73-bc65-5079f493492c',
      modelName: 'DEV - DOVE Primary logistics and inventory level simulation',
      env: 'UAT',
    },
    prod: {
      url: 'https://cloud.anylogic.petronas.com',
      clientID: '4662a888-44a6-4c61-a10c-b61e8b062c63',
      modelName: 'DOVE Primary logistics and inventory level simulation',
      env: 'PROD',
    },
  },
  personaRoles: {
    pds: 'db95dc73-a7d6-4d28-894f-f6a3d158d473',
    demandPlanner: 'b76db15c-8ac9-4613-9ce3-fe80fa53358d',
    retailPricingAnalyst: '4df5dbc5-f220-48b2-b96e-d83fb0b30caf',
    retailPricingAnalystPDB: 'bf86ed9f-e30d-4996-9252-6a55b1735483',
    melakaPlanner: '085d05f6-48cf-4b83-b51c-3f060f937d76',
    commercialPricingAnalyst: 'd4e64c74-b520-4688-81a2-4f4c6c77b521',
    enterpriseOptimizer: '3c3b35f8-d9b1-4789-bd9f-42dcef323ef3',
    RBDDemandPlanner: '6f7dd3ff-9398-43fd-8e3b-4cbe40e4465e',
    CBDDemandPlanner: 'd6ec7073-0403-4720-9fd8-41f1b96a0917',
    petco: '4524873d-a7ac-4e7e-ae07-b50887346baa',
    doveAdmin: 'ef810f5c-e418-468f-8270-6c21b02f5704',
  },
  personaTitles: {
    demandPlanner: 'DOVE - PDB Demand Planner',
    pds: 'DOVE - Primary Distribution Scheduler',
    melakaPlanner: 'DOVE - Melaka Planner',
    enterpriseOptimizer: 'DOVE - Enterprise Optimizer',
    retailPricingAnalyst: 'DOVE - Retail Pricing Analyst',
    retailPricingAnalystPDB: 'DOVE - PDB Retail Pricing Analyst',
    commercialPricingAnalyst: 'DOVE - Commercial Pricing Analyst',
    CBDDemandPlanner: 'CBD - Demand Planner',
    RBDDemandPlanner: 'RBD - Demand Planner',
    petco: 'PETCO - Inventory PETCO',
    doveAdmin: 'DOVE - Admin',
  },
  featureID: {
    mlsPublishButton: 'VESSEL_WEB_MLS_LISTING_PUBLISH',
    mlsAddButton: 'VESSEL_WEB_MLS_LISTING_CREATE',
    mlsRunButton: 'VESSEL_WEB_MLS_LISTING_RUN',
    mlsEditButton: 'VESSEL_WEB_MLS_LISTING_EDIT',
    mlsDeleteButton: 'VESSEL_WEB_MLS_LISTING_DELETE',
    mlsRemarkButton: 'VESSEL_WEB_MLS_LISTING_REMARK',
    mlsVISendButton: 'VESSEL_WEB_MLS_SEND_VI',
    mlsVIEditButton: 'VESSEL_WEB_MLS_EDIT_VI',
    mlsDISendButton: 'VESSEL_WEB_MLS_SEND_DI',
    mlsDIEditButton: 'VESSEL_WEB_MLS_EDIT_DI',
    bethEfficiencyRemark: 'BERTH_EFFICIENCY_REMARK',
    cbdDealerPriceUpdateButton: 'PRICING_WEB_CBD_EDIT',
    mlsVesselSimulator: 'VESSEL_WEB_MLS_SIMULATOR',
  },
  componentID: {
    mlsEdit: 'WEB_MLS_EDIT',
    mlsVI: 'VESSEL_WEB_MLS_VI',
    mlsDI: 'VESSEL_WEB_MLS_DI',
    berthEfficiency: 'VESSEL_WEB_BERTH_OCCUPANCY',
    mlsList: 'VESSEL_WEB_MLS_LISTING',
    mlsVersionPreview: 'WEB_MLS_VERSION_PREVIEW',
    upcomingDeparted: 'WEB_UPCOMING_DEPARTED',
    mppList: 'WEB_MPP_LISTING',
    updateCBDDealer: 'PRICING_WEB_CBD',
    vesselSimulator: 'VESSEL_WEB_MLS_SIMULATOR',
  },
  endpoints: {
    auth: '/auth/login?platform=web',
    getuserclaim: '/auth/token/user',
    getusermultipersona: FeatureFlag.AZUREOPENID
      ? '/auth/token/azure/user?platform=web&applicationName=dove'
      : '/auth/token/user?platform=web&applicationName=dove',
    refreshtokenmultipersona: '/auth/token?platform=web&applicationName=dove',
    refreshtoken: '/auth/token',
    logouttoken: '/auth/token/logout',
    shipmentdetail: '/viz/shipment_details',
    shipmentdetailchange: '/viz/shipment_details2',
    enterprisects: '/viz/productgroups/cts',
    productdetail: '/viz/productgroups',
    refinerylist: '/viz/inventory/refinery/product',
    depotlist: '/viz/inventory/depot/product',
    vesselop: '/viz/operationalhighlights/vessel',
    enterprisectsdrilldown: '/viz/drillDown',
    vesselsoverview: '/viz/operationalhighlights/vessel',
    vesseltracker: '/viz/operationalhighlights/vessel/track',
    pricinginsightsoverall: '/viz/insights/pricing/overall',
    mops: '/viz/productgroups/mops',
    // overallsalesvariance: '/viz/inventory/product/overallsalesvariance',
    overallsalesvariance: '/viz/inventory/product/overallsalesvariance/v1',
    salestrend: '/viz/inventory/depot/product/salestrend',
    overallinv: '/viz/inventory/product/overall',
    depotslist: '/viz/pricing/depots',
    opulist: '/viz/pricing/operationalunits',
    regionlist: '/viz/pricing/regions',
    retailstationlist: '/viz/pricing/retailstations',
    retailstationdetails: '/viz/pricing/retailstations/details',
    salestrenddrilldown: '/viz/inventory/depot/product/salestrend',
    depot: '/viz/inventory/depot/primary',
    loadport: '/viz/inventory/loadports',
    getvessels: '/viz/inventory/vessel',
    overallcts: '/viz/productgroups/overallcts',
    runmls: '/viz/mls/vessel/runmls',
    runmlsstatus: '/viz/mls/vessel/runmls/status',
    mlslisting: '/viz/mls/vessel',
    productdetailpricing: '/viz/productgroups/rtl',
    vesselproducts: '/viz/productgroups/vessel',
    opendi: '/viz/mls/vessel/opendi',
    difilter: '/viz/mls/vessel/divimaster',
    savemls: '/viz/mls/update',
    mpp: '/viz/operationalhighlights/mpp',
    getallrefineries: '/viz/inventory/refinery',
    openvi: '/viz/mls/vessel/openvi',
    mlsversion: '/viz/mls/vessel/version',
    disend: '/viz/mls/vessel/di/send',
    visend: '/viz/mls/vessel/vi/send',
    diupdate: '/viz/mls/vessel/di/update',
    viupdate: '/viz/mls/vessel/vi/update',
    didownloadpdf: '/viz/mls/vessel/di/download',
    vidownloadpdf: '/viz/mls/vessel/vi/download',
    getinventoryreport: '/viz/documents/download/ipsd',
    mlsexcel: '/viz/mls/vessel/download',
    ctsmap: '/viz/productgroups/map',
    ctscommercial: '/viz/productgroups/cbd',
    inventorydepot: '/viz/inventory/depot',
    inventorystation: '/viz/inventory/station',
    alerts: '/viz/alerts',
    alertsdashboard: '/viz/alerts/dashboard',
    inventoryforecatstageone: '/viz/inventory/product/depot/forecasts/stage-one',
    inventoryforecatstagetwo: '/viz/inventory/product/depot/forecasts/stage-two',
    inventoryforecatstagethree: '/viz/inventory/product/depot/forecasts/stage-three',
    overalldemand: '/viz/inventory/product/overalldemand',
    currentinventory: '/viz/inventory/depot/details',
    productdetailcommercialpricing: '/viz/productgroups/cmrcl',
    productdetailcompetitorpricing: '/viz/competitor-price-matrix/products',
    comparisonsummarydetailpricing: '/viz/competitor-price-matrix/comparison-summary',
    berthoccupancy: '/viz/inventory/vessel/berth',
    subproductdetailcommercialpricing: '/viz/product/subproducts',
    mdfarchivelist: '/mdf/demandforecast/versions',
    mdfarchiveversionslist: '/mdf/demandforecast/versions',
    icomdetailcommercialpricing: '/viz/icom/details',
    icommapsummary: '/viz/icom/summary',
    getrefineryliftingtrackerall: '/viz/inventory/refinery/liftingtracker/all',
    getrefineryliftingtrackercoastal: '/viz/inventory/refinery/liftingtracker/coastal',
    getrefineryliftingtrackermpp: '/viz/inventory/refinery/liftingtracker/mpp',
    getrefineryliftingtrackerttlr: '/viz/inventory/refinery/liftingtracker/ttlr',
    mlsmpp: '/viz/mls/mpp',
    upcoming: '/viz/inventory/vessel/upcoming/details',
    departed: '/viz/inventory/vessel/departed',
    mlsdata: '/viz/mls/vessel/details',
    lastdepot: '/viz/pricing/retailstations/productgroup/liftingdata',
    documentsupcoming: '/viz/documents/upcomingvessel',
    documentsdeparted: '/viz/documents/departedvessel',
    documentsberth: '/viz/documents/berthoccupancy',
    downloadq88: '/viz/documents/download/q88',
    downloadstowageplan: '/viz/documents/download/stowageplan',
    downloadtimelog: '/viz/documents/download/timelog',
    downloaddsl: '/viz/documents/download/dsl',
    downloadloadingorder: '/viz/documents/download/loadingorder',
    downloadbillofladding: '/viz/documents/download/billofladding',
    downloadvsr: '/viz/documents/download/vsr',
    cbddealerpricedetails: '/viz/pricing/cbd',
    operationalexpenses: '/viz/vessel/postops/operationalexpenses',
    claimstatus: '',
    vesselperformance: '/viz/vessel/postops/performance',
    vesselclaimdetails: '/dummy/vesselclaimDetails',
    productdetailcountcommercialpricing: '/viz/cbd/product/systemproposedcount',
    interlinkeddepots: '/viz/cbd/interlinkeddepots',
    cbddealerpriceupdate: '/viz/pricing/cbd/update',
    cbdproposedpricelist: '/viz/pricing/cbd/workflow/status',
    cbddownloaddetails: '/viz/pricing/cbd/download',
    mlsversionall: '/viz/mls/vessel/runmls/preview/version',
    mlsversionpreview: '/viz/mls/vessel/runmls/preview',
    cbdusereditedcomments: '/viz/pricing/cbd/workflow/comments',
    vesselutilization: '/viz/vessel/postops/utilization',
    completedshipments: '/viz/vessel/postops/shipmentsummary',
    overalloutturnloss: '/viz/vessel/postops/outturnloss',
    demurrage: '/viz/vessel/postops/demurrage',
    outturnlossdetails: '/viz/vessel/postops/outturnloss/details',
    cbdsendproposalids: '/viz/pricing/cbd/submitproposal',
    inventorydownloaddetails: '/viz/icom/download',
    ctsdownloaddetails: '/viz/cts/download',
    commercialoverallcts: '/viz/productgroups/commercial/overallcts',
    depotproductlist: '/viz/productgroups/depots/rtl',
    downloadsales: '/viz/inventory/depot/product/salestrend/download',
    getavailabledepots: '/viz/inventory/depot/product/mapping',
    getnextreplenishment: '/viz/inventory/depot/product/replenishments',
    getatg: '/viz/atg',
    getmtd: '/viz/mls/vessel/mtd',
    overallsurveyorefficiency: '/viz/mls/vessel/surveyorefficiency',
    surveyorefficiencydetails: '/viz/mls/vessel/surveyorefficiency/details',
    downloadrefineryliftingtracker: '/viz/inventory/refinery/liftingtracker/download',
    cbdgetidrsdata: '/viz/cbd/rs-additive',
    savecbdidrsdata: '/viz/cbd/rs-additive/save',
    fetchmrscbdata: '/viz/revenue-elimination/opu/mrscb',
    fetchptlcldata: '/viz/revenue-elimination/opu/ptcl',
    fetchpptsbbdata: '/viz/revenue-elimination/opu/pptsb',
    fetchpdbdata: '/viz/revenue-elimination/opu/pdb',
    fetchopucosting: '/viz/revenue-elimination/opu',
    revenueelimdownload: '/viz/revenue-elimination/download',
    fetchmdfdata: '/mdf/demandforecast',
    fetchmdfversions: '/mdf/demandforecast/versions',
    mdfmasterlist: '/mdf/demandforecast/master',
    overallefficiencyshipment: '/viz/berthefficiency/overall',
    berthefficiencyshipmentdetails: '/viz/berthefficiency/shipmentdetails',
    deletedmlslist: '/viz/mls/vessel/deletedshipments',
    updatemlsreadcommentstatus: '/viz/mls/vessel/readComments',
    mlsaddusercomment: '/viz/mls/vessel/comments/create',
    getmlscomment: '/viz/mls/vessel/comments',
    mdfexceldownload: '/mdf/demandforecast/download',
    mdfversionsdownload: '/mdf/demandforecast/download',
    mdfarchivedownload: '/mdf/demandforecast/download',
    mdfusermanualdownload: '/mdf/demandforecast/userManual',
    mdfdownload: '/mdf/demandforecast/mdfdownload',
    mdfupload: '/mdf/demandforecast/mdfupload',
    mdftwelvemonthdownload: '/mdf/demandforecast/download/all',
    fetchmdfhistorydepots: '/mdf/demandforecast/analysisreport',
    fetchmdfhistoryproducts: '/mdf/demandforecast/products',
    updatemdf: '/mdf/demandforecast/update',
    checkforversion: '/mdf/demandforecast/checkforversion',
    publishmdf: '/mdf/demandforecast/publish',
    remarkslist: '/mdf/demandforecast/remarks',
    copymdf: '/mdf/demandforecast/merge',
    surveyorshipmentlist: '/viz/berthefficiency/kpisurveyor/company',
    surveyorshipmentactivity: '/viz/berthefficiency/kpisurveyor/shipment',
    schedulershipmentlist: '/viz/berthefficiency/kpischeduler/shipment',
    oilaccshipmentlist: '/viz/berthefficiency/kpioas/shipment',
    supashipmentlist: '/viz/berthefficiency/supa',
    agentcompanylist: '/viz/berthefficiency/agent/agentCompany',
    agentshipmentactivity: '/viz/berthefficiency/agent/shipmentActivity',
    area4bshipmentlist: '/viz/berthefficiency/kpiarea4b/shipment',
    berthkpisummary: '/viz/berthefficiency/kpisummary',
    getuisetting: '/viz/ui-settings/get',
    saveuisetting: '/viz/ui-settings/save',
    publishmls: '/viz/mls/publish',
    downloadoverallberthefficiency: '/viz/berthefficiency/overall/download',
    downloadschedulerefficiency: '/viz/berthefficiency/scheduler/download',
    downloadsupaefficiency: '/viz/berthefficiency/supa/download',
    downloadsurveyorefficiency: '/viz/berthefficiency/surveyorreport/download',
    downloadarea4befficiency: '/viz/berthefficiency/area4b/download',
    downloadoasefficiency: '/viz/berthefficiency/oasreport/download',
    downloadagentreportefficiency: '/viz/berthefficiency/agentreport/download',
    vesselcount: '/viz/inventory/vessel/total/count',
    oocpremium: '/viz/competitor-price-matrix/ooc-premium',
    occcompetitortrend: '/viz/competitor-price-matrix/sales-trend',
    getvesselpcglist: '/viz/mls/vesselpcg',
    getvesselpcgcomment: '/viz/mls/vesselpcg/comments',
    writevesselpcgcomment: '/viz/mls/vesselpcg/comments/create',
    addpcgvessel: '/viz/mls/vesselpcg/add',
    editpcgvessel: '/viz/mls/vesselpcg/update',
    deletevesselpcg: '/viz/mls/vesselpcg/delete',
    depotforecast: '/mdf/demandforecast/depotforecast',
    oocdownload: '/viz/competitor-price-matrix/ooc-sp-excel/download',
    cpdownload: '/viz/customer-performance/download',
    ctsmonthlyvnm: '/viz/cts/monthly-vnm',
    mplwaterfallheadervolume: '/viz/productgroups/cts/monthly-vol',
    simulatorupload: '/viz/simulation/upload',
    simulatorfiles: '/viz/simulation/listfiles',
    simulatorfiledownload: '/viz/simulation/download',
    addberthkpiremarks: '/viz/berthefficiency/kpiremark/add',
    getberthkpiremarks: '/viz/berthefficiency/kpiremark/display',
    editberthkpiremarks: '/viz/berthefficiency/kpiremark/edit',
    unreadkpicomments: '/viz/berthefficiency/kpiremark/unread',
    getsuggestedmlslist: '/viz/mls/vesselpcg/suggestedMls',
    downloadmalaccaquestionnaire: '/viz/documents/download/malaccaquestionnaire',
    downloadwreckcert: '/viz/documents/download/wreckcert',
    downloadissc: '/viz/documents/download/issc',
    mpltooltip: '/viz/productgroups/cts/view',
    vesselidling: '/viz/operationalhighlights/vessel/idling',
    vesseldetailbasics: '/viz/operationalhighlights/vessel/details',
    berthdischarging: '/viz/berthefficiency/afframax',
    cpproduct: '/viz/customer-performance/products',
    cpsubproduct: '/viz/product/subproducts',
    cptable: '/viz/customer-performance/chart-data',
    mopsvtwo: '/viz/productgroups/mops-v2',
    salesexec: '/viz/customer-performance/sales-executive',
    customers: '/viz/customer-performance/customers',
    cplegend: '/viz/customer-performance/legend-data',
    cplowestcp: '/viz/price-comp-stat/lowest-competitor-price',
    cpidglobal: '/viz/price-comp-stat/id-global-price',
    updatemtbenotification: '/viz/mls/vesselpcg/readNotification',
    getvesselplilist: '/viz/mls/vessel/pli',
    deletevesselpli: '/viz/mls/vessel/pli/delete',
    depotproductmapping: '/viz/mls/vessel/pli/depotproductmapping',
    getplicomments: '/viz/mls/vessel/pli/comments',
    addplicomments: '/viz/mls/vessel/pli/comments/create',
    depotslist2: '/viz/customer-performance/depots',
    addplivessel: '/viz/mls/vessel/pli/add',
    editplivessel: '/viz/mls/vessel/pli/update',
    berthefficienscyloadingoverall: '/viz/berthefficiency/loading/overall ',
    berthefficiencyloadingshipments: '/viz/berthefficiency/loading/shipmentdetails',
    deletedpcglist: '/viz/mls/vesselpcg/cancelled',
    movingaverage: '/viz/inventory/product/movingaverage',
    getplisuggestedmlslist: '/viz/mls/vessel/pli/suggestedMls',
    getfullreletsuggestions: '/viz/mls/vessel/pli/suggestions/fullrelet',
    getmlsplidetails: '/viz/mls/vessel/pli/shipment',
    updateplinotification: '/viz/mls/vessel/pli/readnotification',
    bunkersummary: '/viz/operationalhighlights/overallreduction',
    bunkervessel: '/viz/operationalhighlights/vesselreduction',
    plibaseoilcboverall: '/viz/berthefficiency/pli/coastal/overall',
    plibaseoiloboverall: '/viz/berthefficiency/pli/ocean/overall',
    plibaseoilcbshipment: '/viz/berthefficiency/pli/coastal/shipmentdetails',
    plibaseoilobshipment: '/viz/berthefficiency/pli/ocean/shipmentdetails',
    fullreletsuggestionsubmit: '/viz/mls/vessel/pli/suggestions/fullrelet/sendemail',
    fullreletsendmail: '/viz/mls/vessel/pli/fullrelet/email',
    petcogrouplist: '/viz/petco/groups',
    petcotrendslist: '/viz/petco/trends',
    petcomtdlist: '/viz/petco/mtd',
    productpetcogrouplist: '/pdtinv/petco/inventorytrend/api/v1/getTrendsProductList',
    productpetcotrendslist: '/pdtinv/petco/inventorytrend/api/v1/getRefineryInventoryTrend',
    productpetcomtdlist: '/pdtinv/petco/inventorytrend/api/v1/getRefineryInventoryTrendMtd',
    feedstockpetcogrouplist: '/fdstkinv/petco/inventorytrend/api/v1/getFeedstockTrendsProductList',
    feedstockpetcotrendslist:
      '/fdstkinv/petco/inventorytrend/api/v1/getFeedstockRefineryInventoryTrend',
    feedstockpetcomtdlist:
      '/fdstkinv/petco/inventorytrend/api/v1/getFeedstockRefineryInventoryTrendMtd',
    kpilist: '/vizutil/listallkpis',
    searchkpilist: '/vizutil/searchallkpis',
    disablekpidetails: '/vizutil/updatekpistatus',
    kpidetails: '/vizutil/getkpidetails',
    editkpidetails: '/vizutil/updatekpi',
    searchkpidetails: '/vizutil/searchkpidetails',
  },
  depotVolumes: ['KB15', 'KB30', 'KG', 'KL15', 'KL30', 'MT'],
  cbdDetailsPollingFreq: 300000, // 5 Minutes refresh rate for CBD/RBD
  EVENTS_LIST: ['click', 'scroll'],
  openIDConfig: {
    primedev: {
      clientID: '0c15065a-94de-4531-b799-fcfdbb1b2207',
      authority: 'https://login.microsoftonline.com/8b24551d-7c2c-4beb-8b61-95f32d9929ef',
      cacheLocation: 'localStorage', // options localStorage sessionStorage
    },
    primeqaint: {
      clientID: '5555e302-9698-4900-81a1-b5b43b3f9866',
      authority: 'https://login.microsoftonline.com/fa6434db-4921-4ae3-8a0c-60977ad6c33c',
      cacheLocation: 'localStorage', // options localStorage sessionStorage
    },
    qa: {
      clientID: '0c15065a-94de-4531-b799-fcfdbb1b2207',
      authority: 'https://login.microsoftonline.com/8b24551d-7c2c-4beb-8b61-95f32d9929ef',
      cacheLocation: 'localStorage', // options localStorage sessionStorage
    },
    uat: {
      clientID: '143fd145-9f03-43a6-b849-86221f9ae7ab',
      authority: 'https://login.microsoftonline.com/fa6434db-4921-4ae3-8a0c-60977ad6c33c',
      cacheLocation: 'localStorage', // options localStorage sessionStorage
    },
    prod: {
      clientID: 'f7507424-0e70-4d61-97c3-463cc14eac28',
      authority: 'https://login.microsoftonline.com/3b2e8941-7948-4131-978a-b2dfc7295091',
      cacheLocation: 'localStorage', // options localStorage sessionStorage
    },
  },
  scopeInfo: {
    security: ['api://security/security-api'],
  },
  petcoProductType: 'productrefinery',
  petcoFeedstockType: 'feedstockdepot',
};
export default config;
