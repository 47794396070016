// Flags for enabling/disabling a feature
const Featureflag = {
  MULTIPERSONA: true,
  AZUREADFSLOGOUT: true,
  MDF: true,
  MPL: true,
  OCC: true,
  CTSYTDTABLE: true,
  AZUREOPENID: true,
  VESSELSIMULATOR: true,
  CTSPDB: false,
  CTSPDBNOFILTER: false,
  CPPHASE2: true,
  CPTABLE: true,
  CPDNLD: true,
  PETCO: true,
  KPI: true,
};
export default Featureflag;
