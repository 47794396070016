const MALAY_TRANSLATION = {
  locale: 'ms',
  messages: {
    DOVE_SwitchDetails: 'Switch to English',
    DOVE_StationInv: '',
    DOVE_footertitle: '',
    DOVE_MmsStatus: '',
    DOVE_Cargo: '',
    DOVE_Speed: '',
    DOVE_BunkerLevel: '',
    DOVE_Laycan: '',
    DOVE_Agent: '',
    DOVE_VoyagePlan: '',
    DOVE_FreightCost: '',
    DOVE_PoCost: '',
    DOVE_Departed: '',
    DOVE_Eta: '',
    DOVE_CTSOVERALL: '',
    DOVE_OverallInvenotry: '',
    DOVE_Volume: '',
    DOVE_InvCost: '',
    DOVE_KB: '',
    DOVE_RM: '',
    DOVE_RMPERL: '',
    DOVE_Days: '',
    DOVE_StockDays: '',
    DOVE_CTS: '',
    DOVE_OperationalHighlights: '',
    DOVE_CtsShort: '',
    DOVE_NoDataMsg: '',
    DOVE_DepotPerformance: '',
    DOVE_For: '',
    DOVO_LodingMsg: '',
    DOVE_OverallSalesVariance: '',
    DOVE_EnterpriseCTS: '',
    DOVE_Back: '',
    DOVE_CtsOverallGraph: '',
    DOVE_Edit: '',
    DOVE_Download: '',
    DOVE_Send_Vi: '',
    DOVE_Cancel: '',
    DOVE_Update: '',
    DOVE_Lifting_Tracker: '',
    DOVE_Leading_By: '',
    DOVE_Lagging_By: '',
    DOVE_From_Plan: '',
    DOVE_Etc: '',
    DOVE_vessels_overview: '',
    DOVE_GL_CODE: '',
    DOVE_DESCRIPTION: '',
    DOVE_COST: '',
    DOVE_PLANNED_BUDGET: '',
    DOVE_Parcel_no: '',
    DOVE_Loading_Terminal: '',
    DOVE_Mode_of_Transfer: '',
    DOVE_Parcel: '',
    DOVE_View_Complete_Schedule: '',
    DOVE_MLS_ADD_NEW: '',
    DOVE_Save: '',
    DOVE_Gross_Margin_Ytd: '',
    DOVE_Gross_Margin_Mtd: '',
    DOVE_Vol_Ytd: ' ',
    DOVE_Vol_Mtd: '',
    DOVE_Pump_Price: '',
    DOVE_ML: '',
    DOVE_L: '',
    DOVE_M: '',
    DOVE_GL_Codes: '',
    DOVE_Daily: '',
    DOVE_Last_Updated: '',
    DOVE_Estimated_Freight_Cost: '',
    DOVE_Unit_Cost: '',
    DOVE_CurrentInventory: '',
    DOVE_Inventory: '',
    DOVE_Current_Map: '',
    DOVE_Total: '',
    DOVE_Average: '',
    DOVE_IncomingReplenishments: '',
    DOVE_Shipment: '',
    DOVE_Po: '',
    DOVE_PoValue: '',
    DOVE_NewMap: '',
    DOVE_PrevMap: '',
    DOVE_EstGrDate: '',
    DOVE_days: '',
    DOVE_BERTH_OCCUPANCY_REMARKS: '',
    DOVE_days_unit: '',
    DOVE_MAPvsMOPS: '',
    DOVE_OVERALLDEMAND: '',
    DOVE_ACTIVE_REBATES: '',
    DOVE_HI: 'Hi',
    DOVE_VESSEL_TRACKER: '',
    DOVE_BERTH_OCCUPANCY: '',
    DOVE_SENT_ON: '',
    DOVE_DI_TO_BE_SENT: '',
    DOVE_REF: '',
    DOVE_DOCUMENTARY_INSTRUCTIONS: '',
    DOVE_RE: 'RE: ',
    DOVE_OPERATIONAL_EXPENSES: '',
    DOVE_EXPENSE_SUBHEADER: '',
    DOVE_Month_To_Date: '',
    DOVE_MN: '',
    DOVE_TC: '',
    DOVE_SPOT_HIRE: '',
    DOVE_SPOT_HIRE_MONTH: '',
    DOVE_VOYAGE_INSTRUCTIONS: '',
    DOVE_VesselCaps: '',
    DOVE_PORT_STATUS: '',
    DOVE_Cents: '',
    DOVE_Rebate: '',
    DOVE_On_Purchase_of: '',
    DOVE_HELLO: '',
    DOVE_UPCOMING: '',
    DOVE_Increase_last_year: '',
    DOVE_TID: '',
    DOVE_CB01: '',
    DOVE_ORKIM_HARMONY: '',
    DOVE_CB02: '',
    DOVE_CB03: '',
    DOVE_CB04: '',
    DOVE_OB01: '',
    DOVE_0B02: '',
    DOVE_LPG: '',
    DOVE_NAUTICA_JOHOR_BAHRU: '',
    DOVE_CommencedPumpingAt: '',
    DOVE_Q88: '',
    DOVE_STOWAGE_PLAN: '',
    DOVE_DO_No_PDB: '',
    DOVE_PPM_1808_03: '',
    DOVE_John_Doe: '',
    DOVE_POB_delayed_due_to_tug_boat_issue: '',
    DOVE_Arrived_at_Port: '',
    DOVE_NOR_Tendered: '',
    DOVE_Pilot_Onboard: '',
    DOVE_All_Fast: '',
    DOVE_Pumping_Commence: '',
    DOVE_Pumping_Completed: '',
    DOVE_Pilot_Away: '',
    DOVE_Departure: '',
    DOVE_ETA_at_SBAY: '',
    DOVE_Tiffery_Neo: '',
    DOVE_Report_volume_pricing_to_government: '',
    DOVE_VI_TO_BE_SENT: '',
    DOVE_Some_actionable_task_for_the_demand_planner: '',
    DOVE_Map_vs_Mop: '',
    DOVE_EMPTY: ' ',
    DOVE_Print: '',
    DOVE_NO_RECORD_FOUND: '',
    DOVE_TC_VESSEL_UTILIZATION_MTD: '',
    DOVE_DEMURRAGE_MTD: '',
    DOVE_OVERALLTRANSIST_LOSS_MTD: '',
    DOVE_PERFOMANCE_CLAIMS_MTD: '',
    DOVE_TC_VESSEL_PERFORMANCE_OVERVIEW: '',
    DOVE_Time_utilization: '',
    DOVE_Cap_utilization: '',
    DOVE_Avg_speed: '',
    DOVE_Bunker_consumption: '',
    DOVE_Bunker_usage: '',
    DOVE_Pumping_rate: '',
    DOVE_COMPLETED_SHIPMENTS_BY: '',
    DOVE_VoyageSummary: '',
    DOVE_Apply: '',
    DOVE_STATION_PROFILE: '',
    DOVE_ICOM_PRCING_INVENTORY: '',
    DOVE_STOCK_OUT: '',
    DOVE_COMPETEITOR_PRICE_FROM_SALES_MSG: '',
    DOVE_REFINERY_STOCK_MSG: '',
    DOVE_ICOM: '',
    DOVE_COMMENTS: '',
    DOVE_ENTER_COMMENTS: '',
    DOVE_SUBMIT: '',
    DOVE_Etb: '',
    DOVE_NEW_ENTRY: '',
    DOVE_MARK_AS_COMPLETED: '',
    DOVE_MLS_VERSION: '',
    DOVE_VIEW_INPUT_DETAILS: '',
    DOVE_TRANSACTION_ID: '',
    DOVE_LOG_FILE: '',
    DOVE_INPUT_FILE1_TIMESTAMP: '',
    DOVE_INPUT_FILE2_TIMESTAMP: '',
    DOVE_REQUESTED_BY: '',
    DOVE_INITIATION_TIME: '',
    DOVE_COMPLETION_TIME: '',
    DOVE_VIEW_LOG_FILE: '',
    DOVE_MLS_INPUT_DETAILS: '',
    DOVE_MLS_VERSION_DETAILS: '',
    DOVE_Budget: '',
    DOVE_ONE_SPOT_HIRE: '',
    DOVE_Total_Expense: '',
    DOVE_MTD: '',
    DOVE_YTD: '',
    DOVE_NO_DATA_ALERT: '',
    DOVE_NO_DATA_VESSEL: '',
    DOVE_NO_DATA_COMMON: '',
    DOVE_Last_updated_on: '',
    DOVE_View_Details: '',
    DOVE_Menu: '',
    DOVE_MLS_Volume_Validation: '',
    DOVE_Disport_Label: '',
    DOVE_MLS_Doc_Validation: '',
    DOVE_PRODUCT_NOT_SOLD: '',
    DOVE_Select_month: '',
    DOVE_Port: '',
    DOVE_Product_Outturn: '',
    DOVE_LoadedVol: '',
    DOVE_OVERALL_TRANSIT_LOSS_PERCENTAGE: '',
    DOVE_OUTTURN_VOL: '',
    DOVE_Select_year: '',
    DOVE_OPEN: '',
    DOVE_CLOSE: '',
    DOVE_REFERENCE_NUM: '',
    DOVE_PO_NUM: '',
    DOVE_OVERALL_TRANSIT_LOSS: '',
    DOVE_CANCELLED_MLS: '',
    DOVE_PLANNED_VALUE: '',
    DOVE_BASE_VALUE: '',
    DOVE_UPDATED_BY: '',
    DOVE_Laden: '',
    DOVE_Ballast: '',
    DOVE_Shipment_Ref_No: '',
    DOVE_COASTAL_BERTH_LOADING: '',
    DOVE_Total_estimated_demurrage: '',
    DOVE_Previous_shipment: '',
    DOVE_Upcoming_shipment: '',
    DOVE_Available_date_range: '',
    DOVE_Request_relet: '',
    DOVE_Loading_port: '',
    DOVE_Full_relet: '',
    DOVE_Submit_request: '',
    DOVE_PLI_create_label: '',
    DOVE_DDP_KPI_NAME: '',
    DOVE_DDP_KPI_EPIC: '',
    DOVE_DDP_KPI_FEATURE: '',
    DOVE_DDP_KPI_KEY_ATTRIBUTES: '',
    DOVE_KPI_DISABLE: '',
    DOVE_KPI_VIEW: '',
    DOVE_KPI_ENABLE: '',
    DOVE_KEEP: '',
    DOVE_YES_DISABLE: '',
    DOVE_DISABLE_KPI: '',
    DOVE_DISABLE_CONFIRM: '',
  },
};
export default MALAY_TRANSLATION;
